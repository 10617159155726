import React from "react";
import { Link } from "gatsby";

import SEO from "./SEO";
import CenteredPageSection from "./CenteredPageSection";

import why from "../images/why-section-banner.png";
import how from "../images/how-section-banner.jpg";
import about from "../images/about-section-banner.jpg";

const sections = [
  {
    id: "why",
    image: why,
    path: "/why-clhbid/",
    title: "Why CLHbid.com?",
    classNames: "bg-center",
    links: [
      {
        title: "Private Sale",
        path: "/why-clhbid/clhbid-vs-private-sale/",
      },
      {
        title: "Professional Realtors",
        path: "/why-clhbid/clhbid-vs-listing-with-professional-realtors/",
      },
      {
        title: "Public Auction",
        path: "/why-clhbid/clhbid-vs-public-auction/",
      },
      {
        title: "Private Tender",
        path: "/why-clhbid/clhbid-vs-private-tender/",
      },
      {
        title: "Testimonials",
        path: "/why-clhbid/clhbid-testimonials/",
      },
    ],
  },
  {
    id: "how",
    image: how,
    path: "/how-clhbid-works/",
    title: "How We Work",
    classNames: "bg-bottom",
    links: [
      {
        title: "Bidding on Properties",
        path: "/how-clhbid-works/bidding-on-properties-on-clhbid/",
      },
      {
        title: "Starting Bids",
        path: "/how-clhbid-works/starting-bids-on-the-clhbid-auction-platform/",
      },
      {
        title: "En Bloc Process",
        path: "/how-clhbid-works/bidding-on-en-bloc-properties-on-clhbid/",
      },
      {
        title: "Closing the Sale",
        path: "/how-clhbid-works/closing-the-sale-post-auction/",
      },
      {
        title: "Transaction Fee",
        path: "/how-clhbid-works/clhbid-transaction-fee/",
      },
      {
        title: "Terms and Conditions",
        path: "/how-clhbid-works/clhbid-bidding-terms-and-conditions/",
      },
    ],
  },
  {
    id: "about",
    image: about,
    path: "/about-clhbid/",
    title: "About",
    classNames: "bg-center",
    links: [
      {
        title: "The CLHbid.com Team",
        path: "/about-clhbid/clhbid-team/",
      },
      {
        title: "Frequently Asked Questions",
        path: "/about-clhbid/frequently-asked-questions/",
      },
    ],
  },
];

const SectionLayout: React.FC<{
  id: string;
  title: string;
  children: React.ReactNode;
}> = ({ id, title, children }) => {
  const section = sections.find((section) => section.id === id);

  if (!section) throw new Error(`Could not find a section with the id: ${id}`);

  return (
    <>
      <SEO title={title} />
      <div
        style={{ backgroundImage: `url(${section.image}` }}
        className={`w-full h-32 lg:h-80 flex flex-col justify-center align-middle text-center text-white bg-cover ${section.classNames}`}
      >
        <h1>{section.title}</h1>
      </div>
      <CenteredPageSection classNames="flex flex-col lg:flex-row-reverse lg:justify-end align-start">
        <div className="flex-grow">{children}</div>
        <nav className="my-4 lg:my-0 lg:mr-16 flex-shrink-0">
          <ul>
            <li className="border-gray-200 border-t border-b">
              <Link
                to={section.path}
                className="wave-sidebar block py-2 hover:bg-gray-100 transition-colors duration-300 px-4 text-lg"
                activeClassName="bg-clhbid-orange hover:bg-clhbid-orange text-white"
              >
                {section.title}
              </Link>
            </li>
            {section.links.map((link) => (
              <li key={link.path} className="border-gray-200 border-t border-b">
                <Link
                  to={link.path}
                  className="wave-sidebar block py-2 hover:bg-gray-100 transition-colors duration-300 pl-8 pr-6"
                  activeClassName="bg-clhbid-orange hover:bg-clhbid-orange text-white"
                >
                  {link.title}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </CenteredPageSection>
    </>
  );
};

export default SectionLayout;
