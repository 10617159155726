import React from "react";
import { Link as GatsbyLink } from "gatsby";

const classNames =
  "text-clhbid-orange font-bold hover:text-black hover:underline transition-all duration-500";

const Link: React.FC<{
  href?: string;
  to?: string;
  children: React.ReactNode;
}> = ({ href, children, to, ...rest }) => {
  if (href) {
    return (
      <a href={href} className={classNames} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <GatsbyLink to={to} className={classNames} {...rest}>
      {children}
    </GatsbyLink>
  );
};

export default Link;
