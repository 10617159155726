import React from "react";

import PageSection from "./PageSection";

const CenteredPageSection: React.FC<{
  id?: string;
  classNames?: string;
  fullWidthClassNames?: string;
  children?: React.ReactNode;
}> = ({ id, classNames = "", fullWidthClassNames = "", children }) => (
  <PageSection id={id} classNames={fullWidthClassNames}>
    <div className={`py-8 px-3 max-w-screen-xl w-full ${classNames}`}>
      {children}
    </div>
  </PageSection>
);

export default CenteredPageSection;
