import React from "react";

const PageSection: React.FC<{
  id?: string;
  classNames?: string;
  children: React.ReactNode;
}> = ({ children, id, classNames = "" }) => (
  <section
    id={id}
    className={`w-full flex flex-col items-center ${classNames}`}
  >
    {children}
  </section>
);

export default PageSection;
