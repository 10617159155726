import React from "react";
import { MDXProvider } from "@mdx-js/react";

import * as shortcodes from "./Shortcodes";
import SectionLayout from "./SectionLayout";

const PageLayout: React.FC<{
  children: React.ReactNode;
  pageContext: {
    frontmatter: { section: string; title: string };
  };
}> = ({ children, pageContext }) => {
  const { title, section } = pageContext.frontmatter;

  return (
    <SectionLayout title={title} id={section}>
      <MDXProvider components={shortcodes}>{children}</MDXProvider>
    </SectionLayout>
  );
};

export default PageLayout;
