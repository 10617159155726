import React from "react";

const H2: React.FC<{
  children: React.ReactNode;
}> = ({ children, ...rest }) => (
  <h2 className="text-2xl mb-4" {...rest}>
    {children}
  </h2>
);

export default H2;
