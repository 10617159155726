import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/PageLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CLHbid = makeShortcode("CLHbid");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Public Auction`}</h1>
    <p>{`Proceeding by way of a Public Auction using a National or Multi-National Company incurs higher selling costs than Private Sale, but often the benefit is in exposure and, more importantly, access to the auction format. From the Seller’s point of view, often a reserved bid is not allowed, meaning on auction day the seller is ‘all in’ where everything at the end of the day sells, regardless of price.`}</p>
    <p>{`One drawback of the public type of auction is there tends to be less confidentiality as often everyone’s laundry is open for viewing on sale day, in either the farm yard or auction ring. Bidders colluding against the seller becomes easier.`}</p>
    <p>{`Another drawback of using this approach arises when a seller is selling numerous parcels on choice. It is very hard for a large Buyer to acquire the entire ‘en bloc’ farm or ranch to gain economies of scale. Often those Buyers trying to assemble a large block of land refuse to bid at such an auction out of fear of ending up with just one ‘rubber boot’ so to speak (getting only a portion of the parcels and missing some key ones).`}</p>
    <p>{`Also at these auctions, often the home quarter sells first and any buyers missing out on it, wanting the entire farm or ranch, stop bidding and head for their pickup in the parking lot (unlike the `}<CLHbid mdxType="CLHbid" />{` scenario where you can miss out on the home quarter at the outset but still acquire the entire farm or ranch at the conclusion ‘en bloc’). Although one cannot discredit the marketing potential of these large firms, given advances in the internet, social media, etc., it is arguable that the marketing advantage associated with these large firms has been greatly diminished in recent years.`}</p>
    <h2>{`So how do you get around this type of auction scenario?`}</h2>
    <CLHbid mdxType="CLHbid" /> offers so many advantages to both the Seller and the Buyer.
    <p>{`Just like the multi-national guys, we too have a marketing system second to none. Both online and traditional marketing combine to expose your land to thousands of potential buyers.`}</p>
    <p>{`On certain multi-parcel sales, the ‘en bloc’ process kicks in with another level of bidding that ‘raises the bar’ so to speak. Imagine having high bidders of multiple parcels paying a premium to the seller to acquire all the land they’re allowed to bid for in an ‘en bloc’ parcel.`}</p>
    <p>{`When it comes to financing, `}<CLHbid mdxType="CLHbid" />{` provides the Seller with advantages over the multi-nationals. The key with us is flexibility. Just like running your farm or ranch you need to be flexible to succeed. Same with us. For example: If you want to sell by auction and carry some of the debt back at a better interest rate than you can get at your bank, we can do that for you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      