import React from "react";

const H1: React.FC<{
  children: React.ReactNode;
}> = ({ children, ...rest }) => (
  <h1 className="text-4xl mb-4" {...rest}>
    {children}
  </h1>
);

export default H1;
